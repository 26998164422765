$(document).foundation();

$(function(){
ScreenSizeAction();
});


    var $scroll = 100;
    var $speed = 300;

/* ==============================================
 スムーススクロール
=============================================== */

    $('a[href^="#"]').click(function() {
      if($(this).parents('#hamburger').length > 0){
        return false;
      }
        var speed = 400; // ミリ秒
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top;
        $('body,html').animate({ scrollTop: position }, speed, 'swing');
        return false;
    });


/* ==============================================
 アコーディオン
=============================================== */

$(function() {
  $('.accordion-button').on('click', function() {
    if ($(this).hasClass('is-open')) {
      $(this).next('.accordion-target').slideUp();
      $(this).removeClass('is-open');
    } else {
      $(this).next('.accordion-target').slideDown();
      $(this).addClass('is-open');
    }
  });
});


/* ==============================================
 オフキャンバスメニュー
=============================================== */

var $Nav = $('#gnavi');
var distance = '';
var $Body = $('.ccm-page');
var $hamburger = $('#hamburger');

var scrollPos = $(window).scrollTop();
//オフキャンバスメニュー部分　bodyとナビを一緒にずらす

var offCanvas = function(e) {

  //ハンバーガーのアニメーションのためクラスを付ける
  //ナビが開いているとき == on
  if ($Nav.hasClass('on')) {


    $Nav.fadeOut().removeClass('on');
    $hamburger.removeClass('is-active');

    setTimeout(function(){
      $Body.removeClass('nav-is-open');
      $('body,html,.ccm-page').animate({ scrollTop: scrollPos },0, 'swing');
  },100);


  } else {
    scrollPos = $(window).scrollTop();

    $Nav.fadeIn(function(){
    }).addClass('on');

    setTimeout(function(){
       $Body.addClass('nav-is-open');
       $('body,html,.ccm-page').css('top',scrollPos);
       $Nav.css('top',$('.header-top').height());
  },100);

    $hamburger.css('position', 'fixed');


    $hamburger.addClass('is-active');

  }
  return false;
};

$('#hamburger a').on('click', offCanvas);


/* ==============================================
 画面リサイズ時
=============================================== */

var $header = $('#header');

$(window).resize(function() {
  ScreenSizeAction();
});

function ScreenSizeAction() {
  var wid = $(window).outerWidth();
  var $Nav = $('#gnavi');
  var $Body = $('.ccm-page');

  $('meta[name="viewport"]').remove();

  if (wid > 1200) {
    $Body.addClass('is-pc-mode');
    $Body.removeClass('is-sp-mode');

    //$('head').append('<meta name="viewport" content="width=1200">');



    /*

        画像切り替え

     */
    $('.img-change').each(function() {
      $(this).attr("src", $(this).attr("src").replace('_sp', '_pc'));
    });
  } else {
    //SPの時
    $Body.addClass('is-sp-mode');
    $Body.removeClass('is-pc-mode');

    $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0">');


    $('.img-change').each(function() {
      $(this).attr("src", $(this).attr("src").replace('_pc', '_sp'));
    });
  }


  if (wid < 1201 && wid > 960) {
    $Nav.appendTo('#header').css('display', 'block');
  } else {
    $Nav.insertAfter('#hamburger');
    if ($Nav.hasClass('on')) {
      $Nav.css('display', 'block');
    } else {
      if (wid > 990) {
        $Nav.css('display', 'block');
      }else{
        $Nav.css('display', 'none');
      }
    }
  }

      if (wid > 960) {
        $('.gnavi-wrap').css('height','auto');
      }else{
        $('.gnavi-wrap').height($(window).innerHeight() - $('.header-top').outerHeight());
       $Nav.css('top',$('.header-top').height());
      }

    if (wid < 1184 && wid > 960) {
  } else {
    $Nav.insertAfter('#hamburger');

  }
}
